:root {
    --main-color: #123B77;
}

.main_text_color {
    color: var(--main-color) !important;
}

.custom_header_font_1 {
    font-family: "Dancing Script", "Fredoka", sans-serif, cursive;
    font-size: clamp(25px, calc(10px + 3vw), 45px);
}

.custom_header_font_2 {
    font-size: clamp(12px, calc(5px + 1.5vw), 22px);
    font-weight: 400;
}

#header_title {
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
}

#header_bg_color_and_border {
    background-color: #e3eff7 !important;
    height: 100px !important;
    border-bottom: 0.15em solid var(--main-color) !important;
}

.fw-normal {
    font-size: 1.12em !important;
}

.accordion-item {
    font-size: 1em !important;
}


@media (max-width: 768px) {
    .desktop-flags {
        display: none;
    }

    .mobile-flags {
        display: block;
    }
}

@media (min-width: 768px) {
    .desktop-flags {
        display: flex;
    }

    .mobile-flags {
        display: none;
    }
}